export default function titleCase(str: string) {
	const res = str.toLowerCase()
	const resarr = res.split('_')
	for (let i = 0; i < resarr.length; i++) {
		resarr[i] = resarr[i].charAt(0).toUpperCase() + resarr[i].slice(1)
	}
	return resarr.join(' ')
}

export function titleCaseSpace(str: string) {
	const res = str.toLowerCase()
	const resarr = res.split(' ')
	for (let i = 0; i < resarr.length; i++) {
		resarr[i] = resarr[i].charAt(0).toUpperCase() + resarr[i].slice(1)
	}
	return resarr.join(' ')
}

export function titleCaseDash(str: string) {
	const res = str.toLowerCase()
	const resarr = res.split('-')
	for (let i = 0; i < resarr.length; i++) {
		resarr[i] = resarr[i].charAt(0).toUpperCase() + resarr[i].slice(1)
	}
	return resarr.join(' ')
}

export function camelCaseFromSnake(str: string) {
	const res = str.toLowerCase()
	const resarr = res.split('_')
	for (let i = 1; i < resarr.length; i++) {
		resarr[i] = resarr[i].charAt(0).toUpperCase() + resarr[i].slice(1)
	}
	return resarr.join('')
}

export function fixTitleCharacters(str: string) {
	if (!str) return ''
	return str
		.replace(/&amp;/g, '&')
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&#39;/g, "'")
		.replace(/&quot;/g, '"')
}
